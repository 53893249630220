.clase {
    background: linear-gradient(-45deg, #777777, #000000, #5e5e5e, #3a3a3a);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
}

.claseCell {
    background: linear-gradient(-45deg, #777777, #000000, #5e5e5e, #3a3a3a);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 20vh;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
